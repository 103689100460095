<template>
    <div>
        <div class="flex">
            <h1 class="flex-1 ml-5 font-bold text-lg text-header">Services</h1>
            <router-link class="mr-5 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" to="/services/create">
                Create New Service
            </router-link>
        </div>
        <div class="m-5 bg-white">
            <div class="bg-white shadow-md rounded my-6 flex flex-row">
                <div class="w-1/4 flex-initial text-center px-4 py-2 m-2">
                    <input class="form-text" type="text" id="search" name="search" v-model="search" placeholder="Search" @blur="updateSearch()" />
                </div>
                <div class="w-1/4 flex-initial text-center px-4 py-2 m-2">
                    <multiselect v-model="search_statuses" :options="statuses" :searchable="true" :close-on-select="true" :show-labels="false" :multiple="true" track-by="id" label="name" placeholder="Filter by Status" @input="updateSearch()"></multiselect>
                </div>
            </div>
        </div>
        <div class="m-5 bg-white">
            <div class="">
                <div class="bg-white shadow-md rounded my-6">
                    <table class="listing">
                        <thead>
                            <tr>
                                <th><a href="javascript:void(0)" @click="updateSort('id')">ID</a></th>
                                <th><a href="javascript:void(0)" @click="updateSort('name')">Name</a></th>
                                <th><a href="javascript:void(0)" @click="updateSort('service')">Service</a></th>
                                <th><a href="javascript:void(0)" @click="updateSort('status')">Status</a></th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="hover:bg-grey-lighter" v-for="service in services" v-bind:key="service.id">
                                <td class="py-4 px-6 border-b border-grey-light">{{ service.id }}</td>
                                <td class="py-4 px-6 border-b border-grey-light">{{ service.name }}</td>
                                <td class="py-4 px-6 border-b border-grey-light">{{ getServiceName(service.service) }}</td>
                                <td class="py-4 px-6 border-b border-grey-light">
                                    <span v-bind:class="{'bg-green-500': (service.status === 'active'), 'bg-gray-600': (service.status === 'inactive')}" class="text-white font-bold py-1 px-2 rounded-full text-sm">{{ service.status | capitalize }}</span>
                                </td>
                                <td class="py-4 px-6 border-b border-grey-light">
                                    <a href="javascript:void(0);" v-on:click="showIntegrationModal(service)" class="mx-3"><i class="fab fa-wpforms"></i></a>
                                    <router-link :to="{ name: 'edit_service', params: { id: service.id }}" class="mx-3"><i class="fas fa-pencil-alt"></i></router-link>
                                    <a href="javascript:void(0);" v-on:click="confirmDelete(service.id)" class="mx-3"><i class="fas fa-trash-alt"></i></a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <paginate
            :page-count="page_count"
            :click-handler="fetch"
            :prev-text="'Prev'"
            :next-text="'Next'"
            :container-class="'pagination'"
            :page-class="'pagination-page'"
            :prev-class="'pagination-prev'"
            :next-class="'pagination-next'"
            :disabled-class="'pagination-inactive'">
        </paginate>

        <modal name="delete-confirm" class="confirm-modal" :height="220">
            <div class="modal-container">
                <div class="modal-content">
                    <div class="modal-c-title">Delete Service</div>
                    <div class="modal-c-text">Are you sure you want to delete this service?  This action cannot be undone.</div>
                </div>
                <div class="modal-buttons">
                    <button type="button" class="modal-button" @click="$modal.hide('delete-confirm')">Cancel</button>
                    <button type="button" class="modal-button-primary" @click="deleteService()">Delete</button>
                </div>
            </div>
        </modal>

        <modal name="integration-modal" class="confirm-modal" :height="720" :width="1000">
            <div class="modal-container">
                <div class="modal-content">
                    <div class="modal-c-title">Setup Integration</div>
                    <div class="modal-c-text" v-if="integration_service && integration_service.service === 'actblue'">
                        Before you begin, first verify you have an account with ActBlue that is active.  Then, follow these steps:
                        <ol class="list-decimal pl-10 pt-5">
                            <li>Request a webhook from ActBlue by logging into your account, and going to the Webhook Integrations tab.</li>
                            <li>When prompted, provide the following details:
                                <ul class="list-disc pl-10">
                                    <li>Username: <strong>{{ integration_service ? integration_service.params.username : '' }}</strong></li>
                                    <li>Password: <strong>{{ integration_service ? integration_service.params.password : '' }}</strong></li>
                                    <li>URL: <strong>{{ integration_service ? integration_service.webhook_url : '' }}</strong></li>
                                </ul>
                            </li>
                        </ol>
                    </div>

                    <div class="modal-c-text" v-if="integration_service && integration_service.service === 'actionnetwork'">
                        Before you begin, first verify you have an account with Action Network that is active.  Then, follow these steps:
                        <ol class="list-decimal pl-10 pt-5">
                            <li>From the Start Organizing menu in the header (when logged in), select Details, then API &amp; Sync.</li>
                            <li>On the left side of the page, find the Webhooks section and click the New Webhook button.</li>
                            <li>When prompted, provide the following details:
                                <ul class="list-disc pl-10">
                                    <li>URL: <strong>{{ integration_service ? integration_service.webhook_url : '' }}</strong></li>
                                    <li>Trigger: Select <strong>All Actions</strong></li>
                                </ul>
                            </li>
                        </ol>
                    </div>

                    <div class="modal-c-text" v-if="integration_service && integration_service.service === 'mailchimp'">

                        <ol class="list-decimal pl-10 pt-5">

                        </ol>
                    </div>

                    <div class="modal-c-text" v-if="integration_service && (integration_service.service === 'seebottext' || integration_service.service === 'seebottalk')">
                        You're all set!  With SeeBotRun services, no additional setup is required.
                    </div>
                </div>
                <div class="modal-buttons">
                    <button type="button" class="modal-button-primary" @click="$modal.hide('integration-modal')">Close</button>
                </div>
            </div>
        </modal>
    </div>
</template>

<script>
    import axios from 'axios';
    import LayoutApp from '../../layouts/App'

    export default {
        name: 'ListServices',
        components: {
            LayoutApp
        },
        created() {
            this.$emit('update:layout', LayoutApp);
            this.fetch(1);
        },
        data() {
            return {
                services: [],
                delete_id: null,
                page_count: 1,
                sort: 'desc',
                order: 'id',
                statuses: [
                    {id: 'active', name: 'Active'},
                    {id: 'inactive', name: 'Inactive'},
                ],
                search: this.$route.query.search || '',
                search_statuses: [],
                integration_service: null,
            }
        },
        methods: {
            fetch(page) {
                this.current_page = page;

                let search_statuses = this.search_statuses.map(status => status.id)

                axios.get(`/api/v1/services?page=${this.current_page}&sort=${this.sort}&order=${this.order}&search=${this.search}&search_statuses=${search_statuses}`).then(this.refresh);

                this.$router.push({
                    query: {
                        page: this.current_page,
                        sort: this.sort,
                        order: this.order,
                        search: this.search,
                    }
                }).catch(err => {})
            },
            refresh({data}) {
                this.services = data.data;
                this.page_count = data.meta.pages;
                window.scrollTo(0,0);
            },
            confirmDelete(id) {
                this.delete_id = id;

                this.$modal.show('delete-confirm');
            },
            deleteService() {
                axios.delete(`/api/v1/services/${this.delete_id}`).then(this.postDelete);
            },
            postDelete() {
                this.$modal.hide('delete-confirm');
                this.$notify({
                    group: 'alerts',
                    type: 'success',
                    text: 'The service has been deleted',
                    duration: 5000,
                    speed: 1000
                });

                this.fetch(1);

                this.$root.$emit('services_updated');
            },
            updateSort(field) {
                if (field === this.order) {
                    this.sort = (this.sort === 'asc' ? 'desc' : 'asc');
                } else {
                    this.sort = 'asc';
                }

                this.order = field;

                this.fetch(1);
            },
            updateSearch() {
                this.fetch(1);
            },
            getServiceName(name) {
                if (name === 'seebottext') {
                    return 'SeeBotText'
                } else if (name === 'seebottalk') {
                    return 'SeeBotTalk'
                } else if (name === 'seebotchat') {
                    return 'SeeBotChat'
                } else if (name === 'actblue') {
                    return 'ActBlue'
                } else if (name === 'actionnetwork') {
                    return 'Action Network'
                } else if (name === 'mailchimp') {
                    return 'MailChimp'
                }
            },
            showIntegrationModal(service) {
                this.integration_service = service
                this.$modal.show('integration-modal')
            },
        }
    }
</script>
