<template>
  <div>
    <nav class="bg-gray-900 text-white pt-2 md:pt-1 pb-1 px-1 h-13 mt-0 w-full top-0">

        <div class="flex flex-wrap items-center">
            <div class="flex flex-shrink md:w-1/3 justify-center md:justify-start text-white ml-1">
                <a :href="portal_url"><img width="130" height="60" src="/img/SBR_horiz_med_light.png" /></a>
            </div>

            <div class="flex flex-1 md:w-1/3 justify-center md:justify-start text-white px-2" >

			</div>

            <div class="flex w-full pt-2 content-center justify-between md:w-1/3 md:justify-end">
				<ul class="list-reset flex justify-between flex-1 md:flex-none items-center">
				    <li class="flex-1 md:flex-none md:mr-3">
					    <a class="inline-block py-2 px-4 text-white no-underline" :href="help_url" target="_blank">Help</a>
				    </li>
                    <li class="flex-1 md:flex-none md:mr-3">
					    <a class="inline-block py-2 px-4 text-white no-underline" :href="support_url">Support</a>
				    </li>
				    <li class="flex-1 md:flex-none md:mr-3">
						<div class="relative inline-block">
							<button @click="toggleUserDropdown('user_dropdown')" class="drop-button text-white focus:outline-none">
                                <span class="pr-2"></span>
                                Hi, {{user.first_name}}
                                <svg class="h-3 fill-current inline" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/>
                                </svg>
                            </button>
                            <div id="user_dropdown" class="dropdownlist absolute bg-black text-white pin-r mt-3 p-3 overflow-auto z-30 invisible">
                                <a :href="portal_url+'/settings/profile'" class="p-2 hover:bg-grey-darkest text-white text-sm no-underline hover:no-underline block"><i class="fa fa-user fa-fw"></i> Profile</a>
                                <a :href="portal_url+'/settings/account'" class="p-2 hover:bg-grey-darkest text-white text-sm no-underline hover:no-underline block"><i class="fa fa-cog fa-fw"></i> Settings</a>
                                <div class="border border-grey-darkest"></div>
                                <a href="javascript:void(0)" @click="logoutUser()" class="p-2 hover:bg-grey-darkest text-white text-sm no-underline hover:no-underline block"><i class="fas fa-sign-out-alt fa-fw"></i> Log Out</a>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </nav>

    <div class="flex flex-col md:flex-row">
      <div class="bg-nav shadow-lg fixed bottom-0 md:relative pt-5 z-10 h-screen w-full md:w-48">
        <h5 class="pl-2 pb-2 text-sm text-gray-100 uppercase">System</h5>
        <ul class="list-reset pb-10">
            <router-link tag="li" class="py-3 pl-5 text-nav cursor-pointer" to="/"><i class="pr-2 fas fa-home"></i> Home</router-link>
            <router-link tag="li" class="py-3 pl-5 text-nav cursor-pointer" to="/services"><i class="pr-2 fas fa-plug"></i> Services</router-link>
            <router-link tag="li" class="py-3 pl-5 text-nav cursor-pointer" to="/rules"><i class="pr-2 fas fa-directions"></i> Rules</router-link>
            <router-link tag="li" class="py-3 pl-5 text-nav cursor-pointer" to="/requests"><i class="pr-2 fas fa-directions"></i> Requests</router-link>
            <router-link tag="li" class="py-3 pl-5 text-nav cursor-pointer" to="/reports"><i class="pr-2 fas fa-chart-bar"></i> Reports</router-link>
        </ul>
      </div>

      <div class="main-content flex-1 bg-body pt-5 pb-24">
          <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>

    import axios from 'axios';
    import store from '../../store'

    export default {
        name: 'layout-app',
        created() {
            this.fetchUser();
        },
        data() {
            return {
                user: {
                    id: null,
                    first_name: null,
                    last_name: null,
                    settings: {
                        connect: {
                            services: {
                                actblue: false,
                                actionnetwork: false,
                            }
                        }
                    }
                },
                portal_url: process.env.MIX_PORTAL_URL,
                help_url: process.env.MIX_HELP_URL,
                support_url: process.env.MIX_SUPPORT_URL
            }
        },
        methods: {
            fetchUser() {
                axios.get(this.userUrl()).then(this.refreshUser);
            },
            userUrl() {
                return `/api/v1/me`;
            },
            refreshUser({data}) {
                this.user = data.data;
            },
            toggleUserDropdown() {
                document.getElementById('user_dropdown').classList.toggle("invisible");
            },
            logoutUser() {
                this.$store.dispatch('logout')
                    .then(() => {
                        window.location = process.env.MIX_PORTAL_LOGIN_URL
                    })
                    .catch(err => console.log(err))
            }
        }
    }
</script>
