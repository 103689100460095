<template>
    <div>
        <div class="flex">
            <h1 class="flex-1 ml-5 font-bold text-lg text-header">Create New Service</h1>
        </div>
        <div class="flex flex-row">
            <div class="flex-grow">
                <div class="m-5 bg-white shadow-md rounded">
                    <div class="flex">
                        <h2 class="flex-1 p-4">Service Details</h2>
                        <h2 class="py-2 px-4"><a href="javascript:void(0);" @click="showHelp('details')"><i class="far fa-question-circle"></i></a></h2>
                    </div>
                    <div class="pl-4">
                        <p class="text-red-500 text-xs italic" v-if="errors && errors.service">{{ errors.service[0] }}</p>
                    </div>
                    <div class="bg-white my-6 p-4">
                        <div class="w-full">

                        <!-- Row One -->
                        <div class="flex flex-wrap -mx-3 mb-6">
                            <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                            <label class="form-label" for="name">Name</label>
                            <input class="form-text" id="name" type="text" v-model="service.name">
                            <p class="text-red-500 text-xs italic" v-if="errors && errors.name">{{ errors.name[0] }}</p>
                            </div>
                            <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                <label class="form-label" for="service">Service</label>
                                <div class="relative">
                                    <select class="form-select" id="service" name="service" v-model="service.service" @change="serviceUpdated()">
                                        <option :value="null">- Select Service -</option>
                                        <optgroup label="SeeBotRun">
                                            <option value="seebottext">SeeBotText</option>
                                            <option value="seebotchat">SeeBotChat</option>
                                            <option value="seebottalk">SeeBotTalk</option>
                                        </optgroup>
                                        <optgroup label="External Services">
                                            <option value="actblue">ActBlue</option>
                                            <option value="actionnetwork">Action Network</option>
                                            <option value="mailchimp">Mailchimp</option>
                                        </optgroup>
                                    </select>
                                    <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                    <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                                    </div>
                                </div>
                                <p class="text-red-500 text-xs italic" v-if="errors && errors.service">{{ errors.service[0] }}</p>
                            </div>
                        </div>

                        <div class="flex flex-wrap -mx-3 mb-6">
                            <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                <label class="form-label" for="status">Status</label>
                                <div class="relative">
                                    <select class="form-select" id="status" name="status" v-model="service.status">
                                    <option value="active">Active</option>
                                    <option value="inactive">Inactive</option>
                                    </select>
                                    <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                    <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                                    </div>
                                </div>
                                <p class="text-red-500 text-xs italic" v-if="errors && errors.status">{{ errors.status[0] }}</p>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>

                <div class="m-5 bg-white shadow-md rounded" v-if="service.service === 'actblue'">
                    <div class="flex">
                        <h2 class="flex-1 p-4">ActBlue Details</h2>
                        <h2 class="py-2 px-4"><a href="javascript:void(0);" @click="showHelp('details_actblue')"><i class="far fa-question-circle"></i></a></h2>
                    </div>
                    <div class="pl-4">
                        <p class="text-red-500 text-xs italic" v-if="errors && errors.service">{{ errors.service[0] }}</p>
                    </div>
                    <div class="bg-white my-6 p-4">
                        <div class="w-full">
                            <!-- Row One -->
                            <div class="flex flex-wrap -mx-3 mb-6">
                                <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                    <label class="form-label" for="username">Username</label>
                                    <input class="form-text" id="username" type="text" v-model="service.params.username">
                                    <p class="text-red-500 text-xs italic" v-if="errors && errors['params.username']">{{ errors['params.username'][0] }}</p>
                                </div>
                                <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                    <label class="form-label" for="password">Password</label>
                                    <input class="form-text" id="password" type="text" v-model="service.params.password">
                                    <p class="text-red-500 text-xs italic" v-if="errors && errors['params.password']">{{ errors['params.password'][0] }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="m-5 bg-white shadow-md rounded" v-if="service.service === 'actionnetwork'">
                    <div class="flex">
                        <h2 class="flex-1 p-4">Action Network Details</h2>
                        <h2 class="py-2 px-4"><a href="javascript:void(0);" @click="showHelp('details_actionnetwork')"><i class="far fa-question-circle"></i></a></h2>
                    </div>
                    <div class="pl-4">
                        <p class="text-red-500 text-xs italic" v-if="errors && errors.service">{{ errors.service[0] }}</p>
                    </div>
                    <div class="bg-white my-6 p-4">
                        <div class="w-full">
                            <!-- Row One -->
                            <div class="flex flex-wrap -mx-3 mb-6">
                                <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                    <label class="form-label" for="api_key">API Key</label>
                                    <input class="form-text" id="api_key" type="text" v-model="service.params.api_key">
                                    <p class="text-red-500 text-xs italic" v-if="errors && errors['params.api_key']">{{ errors['params.api_key'][0] }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="m-5 bg-white shadow-md rounded" v-if="service.service === 'mailchimp'">
                    <div class="flex">
                        <h2 class="flex-1 p-4">Mailchimp Details</h2>
                        <h2 class="py-2 px-4"><a href="javascript:void(0);" @click="showHelp('details_mailchimp')"><i class="far fa-question-circle"></i></a></h2>
                    </div>
                    <div class="pl-4">
                        <p class="text-red-500 text-xs italic" v-if="errors && errors.service">{{ errors.service[0] }}</p>
                    </div>
                    <div class="bg-white my-6 p-4">
                        <div class="w-full">
                            <!-- Row One -->
                            <div class="flex flex-wrap -mx-3 mb-6">
                                <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                    <label class="form-label" for="api_key">API Token</label>
                                    <input class="form-text" id="api_key" type="text" v-model="service.params.api_key">
                                    <p class="text-red-500 text-xs italic" v-if="errors && errors['params.api_key']">{{ errors['params.api_key'][0] }}</p>
                                </div>
                                <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                    <label class="form-label" for="mc_server_prefix">Server Prefix</label>
                                    <input class="form-text" id="mc_server_prefix" type="text" v-model="service.params.mc_server_prefix">
                                    <p class="text-red-500 text-xs italic" v-if="errors && errors['params.mc_server_prefix']">{{ errors['params.mc_server_prefix'][0] }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="m-5 bg-white shadow-md rounded" v-if="service.service === 'seebottext'">
                    <div class="flex">
                        <h2 class="flex-1 p-4">SeeBotText Details</h2>
                        <h2 class="py-2 px-4"><a href="javascript:void(0);" @click="showHelp('details_seebottext')"><i class="far fa-question-circle"></i></a></h2>
                    </div>
                    <div class="pl-4">
                        <p class="text-red-500 text-xs italic" v-if="errors && errors.service">{{ errors.service[0] }}</p>
                    </div>
                    <div class="bg-white my-6 p-4">
                        <div class="w-full">
                            <!-- Row One -->
                            <div class="flex flex-wrap -mx-3 mb-6">
                                <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                    <label class="form-label" for="number_id">Number</label>
                                    <div class="relative">
                                        <select class="form-select" id="number_id" name="number_id" v-model="service.params.number_id">
                                            <option :value="null">- Select Number -</option>
                                            <option v-for="number in seebottext_numbers" v-bind:key="number.id" :value="number.id">{{ number.name }} ({{ number.number }})</option>
                                        </select>
                                        <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                            <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                                        </div>
                                    </div>
                                    <p class="text-red-500 text-xs italic" v-if="errors && errors['params.number_id']">{{ errors['params.number_id'][0] }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



                <div class="flex flex-wrap m-5 px-3 pt-3">
                    <button class="btn btn-primary" v-on:click="save">
                        Create Service
                    </button>

                    <button class="btn btn-secondary" v-on:click="cancel">
                        Cancel
                    </button>
                </div>
            </div>

            <div class="w-64" v-if="show_help_section">
                <div class="m-5 ml-0 bg-white shadow-md rounded bg-indigo-100" v-if="show_help_section === 'details'">
                    <h2 class="p-4">Service Help</h2>
                    <div class="px-4 pb-4">
                        <div class="w-full">
                            <p class="text-xs pb-2">Services are available for users to dial in (inbound calls) or connect to through a webform or API call (outbound call).  Users will connect through either inbound or outbound methods to
                                interact with a Sequence provided via this system.
                            </p>
                            <p class="text-xs italic pb-2"><strong>Name</strong> - Provide a descriptive, unique name for the service to help identify it for future edits and use.  Example: Contact Congress Hotline.</p>
                            <p class="text-xs italic pb-2"><strong>Service</strong> - Use the search button to the right to find a Local or Tollfree Service that's available, based on area code, state, or contained services.</p>
                            <p class="text-xs italic pb-2"><strong>Inbound Sequence</strong> - If the service is going to be used for inbound calls from users (users will dial this service to connect), select a Sequence to handle
                                the inbound call processing.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
    import LayoutApp from '../../layouts/App'
    import Vue from 'vue'

    export default {
        name: 'CreateService',
        components: {
            LayoutApp
        },
        created() {
            this.$emit('update:layout', LayoutApp)
            this.fetchUser()
        },
        data() {
            return {
                service: {
                    name: '',
                    service: null,
                    status: 'active',
                    params: {
                        username: '',
                        password: '',
                        api_key: '',
                        mc_server_prefix: '',
                        number_id: null,
                    },
                },
                seebottext_numbers: [],
                errors: [],
                show_help_section: null,
            }
        },
        methods: {
            fetchUser() {
                axios.get(this.userUrl()).then(this.refreshUser);
            },
            userUrl() {
                return `/api/v1/me`;
            },
            refreshUser({data}) {
                this.user = data.data;

                if (this.user.permissions.text) {
                    this.fetchTextNumbers()
                }
            },
            fetchTextNumbers() {
                axios.get(`${process.env.MIX_TEXT_URL}numbers?per_page=-1`).then(this.refreshTextNumbers);
            },
            refreshTextNumbers({data}) {
                this.seebottext_numbers = data.data;
            },
            save() {
                axios.post('/api/v1/services', this.service).then(response => {
                    this.$notify({
                        group: 'alerts',
                        type: 'success',
                        title: 'The service has been created',
                        duration: 5000,
                        speed: 1000
                    });

                    this.$router.push({name: 'list_services'});
                }).catch(error => {
                    if (error.response.status === 422) {
                        this.errors = error.response.data.errors || {};
                    }
                });
            },
            cancel() {
                this.$router.back();
            },
            serviceUpdated() {

            },
            showHelp(item) {
                if (item == this.show_help_section) {
                    this.show_help_section = null
                } else {
                    this.show_help_section = item
                }
            },
        }
    }

</script>
