<template>
    <div>
        <div class="flex">
            <h1 class="flex-1 ml-5 text-lg font-bold text-header">Create New Rule</h1>
        </div>
        <div class="flex flex-row">
            <div class="flex-grow">
                <div class="m-5 bg-white rounded shadow-md">
                    <div class="flex">
                        <h2 class="flex-1 p-4">Rule Details</h2>
                        <h2 class="px-4 py-2"><a href="javascript:void(0);" @click="showHelp('details')"><i class="far fa-question-circle"></i></a></h2>
                    </div>
                    <div class="pl-4">
                        <p class="text-xs italic text-red-500" v-if="errors && errors.rule">{{ errors.rule[0] }}</p>
                    </div>
                    <div class="p-4 my-6 bg-white">
                        <div class="w-full">
                            <!-- Row One -->
                            <div class="flex flex-wrap mb-6 -mx-3">
                                <div class="w-full px-3 mb-6 md:w-1/2 md:mb-0">
                                    <label class="form-label" for="name">Name</label>
                                    <input class="form-text" id="name" type="text" v-model="rule.name">
                                    <p class="text-xs italic text-red-500" v-if="errors && errors.name">{{ errors.name[0] }}</p>
                                </div>
                                <div class="w-full px-3 mb-6 md:w-1/2 md:mb-0">
                                    <label class="form-label" for="status">Status</label>
                                    <div class="relative">
                                        <select class="form-select" id="status" name="status" v-model="rule.status">
                                        <option value="active">Active</option>
                                        <option value="inactive">Inactive</option>
                                        </select>
                                        <div class="absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 pointer-events-none">
                                        <svg class="w-4 h-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                                        </div>
                                    </div>
                                    <p class="text-xs italic text-red-500" v-if="errors && errors.status">{{ errors.status[0] }}</p>
                                </div>
                            </div>

                            <!-- Row Two -->
                            <div class="flex flex-wrap mb-6 -mx-3">
                                <div class="w-full px-3 mb-6 md:w-1/2 md:mb-0">
                                    <label class="form-label" for="sservice_idervice">Service</label>
                                    <div class="relative">
                                        <select class="form-select" id="service_id" name="service_id" @change="updateService()" v-model="rule.service">
                                            <option :value="null">- Select Service -</option>
                                            <option v-for="service in services" v-bind:key="service.id" :value="service" v-show="service.service !== 'mailchimp'">{{ service.name }}</option>
                                        </select>
                                        <div class="absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 pointer-events-none">
                                            <svg class="w-4 h-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                                        </div>
                                    </div>
                                    <p class="text-xs italic text-red-500" v-if="errors && errors.service_id">{{ errors.service_id[0] }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="m-5 bg-white rounded shadow-md" v-if="rule.service !== null">
                    <div class="flex">
                        <h2 class="flex-1 p-4">Rule Criteria</h2>
                        <h2 class="px-4 py-2"><a href="javascript:void(0);" @click="showHelp('criteria')"><i class="far fa-question-circle"></i></a></h2>
                    </div>
                    <div class="pl-4">
                        <p class="text-xs italic text-red-500" v-if="errors && errors['params.criteria']">{{ errors['params.criteria'][0] }}</p>
                    </div>
                    <div class="p-4 bg-white">
                        <div class="w-full m-6">

                            <!-- Row 1 -->
                            <div class="mb-4 mr-4 bg-gray-100 border-2 border-gray-300 border-solid" v-for="(criteria, criteria_index) in rule.params.criteria" v-bind:key="criteria_index">
                                <h3 class="p-4">Criteria Details</h3>
                                <p class="text-xs italic text-red-500" v-if="errors && errors['params.criteria.'+criteria_index]">{{ errors['params.criteria.'+criteria_index][0] }}</p>

                                <div class="flex flex-wrap my-3">
                                    <div class="w-full px-3 mb-6 md:w-1/2 md:mb-0" v-if="rule.service && rule.service.service === 'actblue'">
                                        <label class="form-label" for="criteria_type">Type</label>
                                        <multiselect v-model="criteria.types" :options="actblue_criteria_types" :searchable="true" :close-on-select="true" :show-labels="false" :multiple="true" placeholder="Select a Type"></multiselect>
                                        <p class="text-xs italic text-red-500" v-if="errors && errors['params.criteria.'+criteria_index+'.types']">{{ errors['params.criteria.'+criteria_index+'.types'][0] }}</p>
                                    </div>
                                    <div class="w-full px-3 mb-6 md:w-1/2 md:mb-0" v-if="rule.service && rule.service.service === 'seebottext'">
                                        <label class="form-label" for="criteria_type">Type</label>
                                        <multiselect v-model="criteria.types" :options="seebottext_criteria_types" :searchable="true" :close-on-select="true" :show-labels="false" :multiple="true" placeholder="Select a Type"></multiselect>
                                        <p class="text-xs italic text-red-500" v-if="errors && errors['params.criteria.'+criteria_index+'.types']">{{ errors['params.criteria.'+criteria_index+'.types'][0] }}</p>
                                    </div>
                                    <div class="w-full px-3 mb-6 md:w-1/2 md:mb-0" v-if="rule.service && rule.service.service === 'seebottalk'">
                                        <label class="form-label" for="criteria_type">Type</label>
                                        <multiselect v-model="criteria.types" :options="seebottalk_criteria_types" :searchable="true" :close-on-select="true" :show-labels="false" :multiple="true" placeholder="Select a Type"></multiselect>
                                        <p class="text-xs italic text-red-500" v-if="errors && errors['params.criteria.'+criteria_index+'.types']">{{ errors['params.criteria.'+criteria_index+'.types'][0] }}</p>
                                    </div>
                                    <div class="w-full px-3 mb-6 md:w-1/2 md:mb-0">
                                        <label class="form-label" for="criteria_operator">Operator</label>
                                        <div class="relative">
                                            <select class="form-select" v-model="criteria.attribute_operator">
                                                <option value="AND">AND</option>
                                                <option value="OR">OR</option>
                                            </select>
                                            <div class="absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 pointer-events-none">
                                                <svg class="w-4 h-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="flex flex-wrap mx-4 mb-4 bg-gray-300 border-2 border-gray-600 border-solid" v-for="(attribute, attribute_index) in criteria.attributes" v-bind:key="attribute_index">
                                    <p class="text-xs italic text-red-500" v-if="errors && errors['params.criteria.'+criteria_index+'.attributes.'+attribute_index]">{{ errors['params.criteria.'+criteria_index+'.attributes.'+attribute_index][0] }}</p>

                                    <div class="w-full px-3 mb-6 md:w-1/4 md:mb-0" v-if="rule.service.service === 'actionnetwork' || rule.service.service === 'actblue'">
                                        <label class="form-label" for="attribute_path">Path</label>
                                        <input class="form-text" id="attribute_path" type="text" v-model="attribute.path" :placeholder="getPlatformPlaceholder(rule.service.service)">
                                        <p class="text-xs italic text-red-500" v-if="errors && errors['params.criteria.'+criteria_index+'.attributes.'+attribute_index+'.path']">{{ errors['params.criteria.'+criteria_index+'.attributes.'+attribute_index+'.path'][0] }}</p>
                                    </div>
                                    <div class="w-full px-3 mb-6 md:w-1/4 md:mb-0" v-if="rule.service.service === 'seebottext'">
                                        <label class="form-label" for="attribute_id">Attribute</label>
                                        <div class="relative">
                                            <select class="form-select" v-model="attribute.attribute_id">
                                                <option :value="null">- Select Attribute -</option>
                                                <option v-for="attribute in seebottext_attributes" v-bind:key="attribute.id" :value="attribute.id">{{ attribute.name }}</option>
                                            </select>
                                            <div class="absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 pointer-events-none">
                                                <svg class="w-4 h-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                                </svg>
                                            </div>
                                        </div>
                                        <p class="text-xs italic text-red-500" v-if="errors && errors['params.criteria.'+criteria_index+'.attributes.'+attribute_index+'.attribute_id']">{{ errors['params.criteria.'+criteria_index+'.attributes.'+attribute_index+'.attribute_id'][0] }}</p>
                                    </div>
                                    <div class="w-full px-3 mb-6 md:w-1/4 md:mb-0" v-if="rule.service.service === 'seebottalk'">
                                        <label class="form-label" for="attribute_id">Attribute</label>
                                        <div class="relative">
                                            <select class="form-select" v-model="attribute.attribute_id">
                                                <option :value="null">- Select Attribute -</option>
                                                <option v-for="attribute in seebottalk_attributes" v-bind:key="attribute.id" :value="attribute.id">{{ attribute.name }}</option>
                                            </select>
                                            <div class="absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 pointer-events-none">
                                                <svg class="w-4 h-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                                </svg>
                                            </div>
                                        </div>
                                        <p class="text-xs italic text-red-500" v-if="errors && errors['params.criteria.'+criteria_index+'.attributes.'+attribute_index+'.attribute_id']">{{ errors['params.criteria.'+criteria_index+'.attributes.'+attribute_index+'.attribute_id'][0] }}</p>
                                    </div>
                                    <div class="w-full px-3 mb-6 md:w-1/4 md:mb-0">
                                        <label class="form-label" for="attribute_operator">Operator</label>
                                        <div class="relative">
                                            <select class="form-select" v-model="attribute.operator">
                                                <option value="=">is equal to</option>
                                                <option value="!=">is not equal to</option>
                                                <option value="<">is less than</option>
                                                <option value="<=">is less than or equal to</option>
                                                <option value=">">is greater than</option>
                                                <option value=">=">is greater than or equal to</option>
                                                <option value="exists">exists</option>
                                                <option value="not_exists">does not exist</option>
                                            </select>
                                            <div class="absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 pointer-events-none">
                                                <svg class="w-4 h-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                                </svg>
                                            </div>
                                        </div>
                                        <p class="text-xs italic text-red-500" v-if="errors && errors['params.criteria.'+criteria_index+'.attributes.'+attribute_index+'.operator']">{{ errors['params.criteria.'+criteria_index+'.attributes.'+attribute_index+'.operator'][0] }}</p>
                                    </div>
                                    <div class="w-full px-3 mb-6 md:w-1/4 md:mb-0" v-if="attribute.operator !== 'exists' && attribute.operator !== 'not_exists'">
                                        <label class="form-label" for="attribute_value">Value</label>
                                        <input class="form-text" id="attribute_value" type="text" v-model="attribute.value" placeholder="">
                                        <p class="text-xs italic text-red-500" v-if="errors && errors['params.criteria.'+criteria_index+'.attributes.'+attribute_index+'.value']">{{ errors['params.criteria.'+criteria_index+'.attributes.'+attribute_index+'.value'][0] }}</p>
                                    </div>
                                    <div class="w-full px-3 pt-6 mb-6 md:w-1/4 md:mb-0">
                                        <button class="btn btn-alert" @click="removeAttribute(criteria_index, attribute_index)"><i class="fas fa-minus-circle"></i> Remove Attribute</button>
                                    </div>
                                </div>
                                <div class="w-full mx-3 mb-6 text-left">
                                    <button class="px-4 py-2 mr-3 font-bold text-white bg-blue-500 rounded hover:bg-blue-700" @click="addAttribute(criteria_index)"><i class="fas fa-plus-circle"></i> Add Attribute</button>
                                </div>
                                <div class="w-full mx-3 mb-6 text-right">
                                    <button class="btn btn-alert" @click="removeCriteria(criteria_index)"><i class="fas fa-minus-circle"></i> Remove Criteria</button>
                                </div>
                            </div>

                            <div class="flex flex-wrap mx-3 mb-6">
                                <button class="px-4 py-2 mr-3 font-bold text-white bg-blue-500 rounded hover:bg-blue-700" @click="addCriteria()"><i class="fas fa-plus-circle"></i> Add Criteria</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="m-5 bg-white rounded shadow-md" v-if="rule.service !== null">
                    <div class="flex">
                        <h2 class="flex-1 p-4">Rule Actions</h2>
                        <h2 class="px-4 py-2"><a href="javascript:void(0);" @click="showHelp('actions')"><i class="far fa-question-circle"></i></a></h2>
                    </div>
                    <div class="pl-4">
                        <p class="text-xs italic text-red-500" v-if="errors && errors['params.actions']">{{ errors['params.actions'][0] }}</p>
                    </div>
                    <div class="p-4 bg-white">
                        <div class="w-full m-6">

                            <!-- Row 1 -->
                            <div class="mb-4 mr-4 bg-gray-100 border-2 border-gray-300 border-solid" v-for="(action, action_index) in rule.params.actions" v-bind:key="action_index">
                                <h3 class="p-4">Action Details</h3>
                                <p class="text-xs italic text-red-500" v-if="errors && errors['params.actions.'+action_index]">{{ errors['params.actions.'+action_index][0] }}</p>

                                <div class="flex flex-wrap my-3">
                                    <div class="w-full px-3 mb-6 md:w-1/2 md:mb-0">
                                        <label class="form-label" for="action_service">Service</label>
                                        <div class="relative">
                                            <select class="form-select" id="action_service_id" name="action_service_id" @change="updateActionService(action_index)" v-model="action.service">
                                                <option :value="null">- Select Service -</option>
                                                <option v-for="service in services" v-bind:key="service.id" :value="service" v-show="rule.service.service !== service.service && service.service !== 'actblue'">{{ service.name }}</option>
                                            </select>
                                            <div class="absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 pointer-events-none">
                                                <svg class="w-4 h-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                                            </div>
                                        </div>
                                        <p class="text-xs italic text-red-500" v-if="errors && errors['params.actions.'+action_index+'.service']">{{ errors['params.actions.'+action_index+'.service'][0] }}</p>
                                    </div>
                                    <div class="w-full px-3 mb-6 md:w-1/2 md:mb-0" v-if="action.service && (action.service.service === 'seebottext' || action.service.service === 'seebottalk')">
                                        <label class="form-label" for="action_sequence">Sequence</label>
                                        <div class="relative">
                                            <select class="form-select" v-model="action.trigger.sequence_id">
                                                <option :value="null">- Select Sequence -</option>
                                                <option v-for="sequence in action.sequences" v-bind:key="sequence.id" :value="sequence.id">{{ sequence.name }}</option>
                                            </select>
                                            <div class="absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 pointer-events-none">
                                                <svg class="w-4 h-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="w-full px-3 mb-6 md:w-1/2 md:mb-0" v-if="action.service && (action.service.service === 'mailchimp')">
                                        <label class="form-label" for="action_audience">Audience</label>
                                        <div class="relative">
                                            <select class="form-select" v-model="action.trigger.audience_id">
                                                <option :value="null">- Select Audience -</option>
                                                <option v-for="audience in action.audiences" v-bind:key="audience.id" :value="audience.id">{{ audience.name }}</option>
                                            </select>
                                            <div class="absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 pointer-events-none">
                                                <svg class="w-4 h-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="flex flex-wrap my-3" v-if="action.service && (action.service.service === 'seebottext' || action.service.service === 'seebottalk')">
                                    <div class="w-full px-3 mb-6 md:w-1/2 md:mb-0">
                                        <label class="form-label" for="action_queued">Queued</label>
                                        <select class="form-select" v-model="action.trigger.queued">
                                            <option :value="true">Yes</option>
                                            <option :value="false">No</option>
                                        </select>
                                        <p class="text-xs italic text-red-500" v-if="errors && errors['params.actions.'+action_index+'.trigger.queued']">{{ errors['params.actions.'+action_index+'.trigger.queued'][0] }}</p>
                                    </div>
                                    <div class="w-full px-3 mb-6 md:w-1/2 md:mb-0">
                                        <label class="form-label" for="action_subscribers">Subscribers</label>
                                        <div class="relative">
                                            <select class="form-select" v-model="action.trigger.subscribers">
                                                <option value="all">All</option>
                                                <option value="new">New Subscribers Only</option>
                                                <option value="existing">Existing Subscribers Only</option>
                                            </select>
                                            <div class="absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 pointer-events-none">
                                                <svg class="w-4 h-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="flex flex-wrap my-3" v-if="action.service && (action.service.service === 'mailchimp')">
                                    <div class="w-full px-3 mb-6 md:w-1/2 md:mb-0">
                                        <label class="form-label" for="action_trigger_optin">OptIn</label>
                                        <select class="form-select" v-model="action.trigger.optin">
                                            <option value="subscribed">Immediately</option>
                                            <option value="pending">Require Confirmation</option>
                                        </select>
                                        <p class="text-xs italic text-red-500" v-if="errors && errors['params.actions.'+action_index+'.trigger.optin']">{{ errors['params.actions.'+action_index+'.trigger.optin'][0] }}</p>
                                    </div>
                                </div>

                                <div class="flex flex-wrap mx-4 mb-4 bg-gray-300 border-2 border-gray-600 border-solid" v-for="(mapping, mapping_index) in action.mappings" v-bind:key="mapping_index">
                                    <p class="text-xs italic text-red-500" v-if="errors && errors['params.actions.'+action_index+'.mappings.'+mapping_index]">{{ errors['params.actions.'+action_index+'.mappings.'+attribute_index][0] }}</p>

                                    <!-- Source Path-->
                                    <div class="w-full px-3 mb-6 md:w-1/5 md:mb-0" v-if="rule.service.service === 'actionnetwork' || rule.service.service === 'actblue' || rule.service.service === 'mailchimp'">
                                        <div v-if="isMappingDisabled(action, mapping)">
                                            <label class="form-label" for="source_path">Source Path</label>
                                            <input class="form-text" id="source_path" type="text" v-model="mapping.source_path" :placeholder="getPlatformPlaceholder(rule.service.service)" disabled>
                                            <p class="text-xs italic text-red-500" v-if="errors && errors['params.actions.'+action_index+'.mappings.'+mapping_index+'.source_path']">{{ errors['params.actions.'+action_index+'.mappings.'+mapping_index+'.source_path'][0] }}</p>
                                        </div>
                                        <div v-if="!isMappingDisabled(action, mapping)">
                                            <label class="form-label" for="source_path">Source Path</label>
                                            <input class="form-text" id="source_path" type="text" v-model="mapping.source_path" :placeholder="getPlatformPlaceholder(rule.service.service)">
                                            <p class="text-xs italic text-red-500" v-if="errors && errors['params.actions.'+action_index+'.mappings.'+mapping_index+'.source_path']">{{ errors['params.actions.'+action_index+'.mappings.'+mapping_index+'.source_path'][0] }}</p>
                                        </div>
                                    </div>
                                    <!-- Source Attribute -->
                                    <div class="w-full px-3 mb-6 md:w-1/5 md:mb-0" v-if="rule.service.service === 'seebottalk'">
                                        <label class="form-label" for="source_attribute_id">Source Attribute</label>
                                        <div class="relative" v-if="!isMappingDisabled(action, mapping)">
                                            <select class="form-select" v-model="mapping.source_attribute_id">
                                                <option :value="null">- Select Attribute -</option>
                                                <option v-for="attribute in seebottalk_attributes" v-bind:key="attribute.id" :value="attribute.id">{{ attribute.name }}</option>
                                            </select>
                                            <div class="absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 pointer-events-none">
                                                <svg class="w-4 h-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                                </svg>
                                            </div>
                                        </div>
                                        <div class="relative" v-if="isMappingDisabled(action, mapping)">
                                            <select class="form-select" v-model="mapping.source_attribute_id" disabled>
                                                <option :value="1">Phone Number</option>
                                            </select>
                                            <div class="absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 pointer-events-none">
                                                <svg class="w-4 h-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                                </svg>
                                            </div>
                                        </div>
                                        <p class="text-xs italic text-red-500" v-if="errors && errors['params.actions.'+action_index+'.mappings.'+mapping_index+'.source_attribute_id']">{{ errors['params.actions.'+action_index+'.mappings.'+mapping_index+'.source_attribute_id'][0] }}</p>
                                    </div>
                                    <div class="w-full px-3 mb-6 md:w-1/5 md:mb-0" v-if="rule.service.service === 'seebottext'">
                                        <label class="form-label" for="source_attribute_id">Source Attribute</label>
                                        <div class="relative" v-if="!isMappingDisabled(action, mapping)">
                                            <select class="form-select" v-model="mapping.source_attribute_id">
                                                <option :value="null">- Select Attribute -</option>
                                                <option v-for="attribute in seebottext_attributes" v-bind:key="attribute.id" :value="attribute.id">{{ attribute.name }}</option>
                                            </select>
                                            <div class="absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 pointer-events-none">
                                                <svg class="w-4 h-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                                </svg>
                                            </div>
                                        </div>
                                        <div class="relative" v-if="isMappingDisabled(action, mapping)">
                                            <select class="form-select" v-model="mapping.source_attribute_id" disabled>
                                                <option :value="1">Phone Number</option>
                                            </select>
                                            <div class="absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 pointer-events-none">
                                                <svg class="w-4 h-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                                </svg>
                                            </div>
                                        </div>
                                        <p class="text-xs italic text-red-500" v-if="errors && errors['params.actions.'+action_index+'.mappings.'+mapping_index+'.source_attribute_id']">{{ errors['params.actions.'+action_index+'.mappings.'+mapping_index+'.source_attribute_id'][0] }}</p>
                                    </div>

                                    <!-- Converter -->
                                    <div class="w-full px-3 mb-6 md:w-1/5 md:mb-0">
                                        <label class="form-label" for="mapping_converter">Converter</label>
                                        <div class="relative">
                                            <select class="form-select" v-model="mapping.converter_rule" :disabled="isMappingDisabled(action, mapping)">
                                                <option :value="null">- Select -</option>
                                                <option value="ucwords">Uppercase Words</option>
                                                <option value="to_upper">Uppercase All</option>
                                                <option value="to_lower">Lowercase All</option>
                                            </select>
                                            <div class="absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 pointer-events-none">
                                                <svg class="w-4 h-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                                </svg>
                                            </div>
                                        </div>
                                        <p class="text-xs italic text-red-500" v-if="errors && errors['params.actions.'+action_index+'.mappings.'+mapping_index+'.converter_rule']">{{ errors['params.actions.'+action_index+'.mappings.'+mapping_index+'.converter_rule'][0] }}</p>
                                    </div>

                                    <!-- Destination Attribute -->
                                    <div class="w-full px-3 mb-6 md:w-1/5 md:mb-0" v-if="action.service.service === 'seebottalk'">
                                        <label class="form-label" for="mapping_attribute">Destination Attribute</label>
                                        <div class="relative" v-if="isMappingDisabled(action, mapping)">
                                            <select class="form-select" v-model="mapping.destination_attribute_id" disabled>
                                                <option :value="1">Phone Number</option>
                                            </select>
                                            <div class="absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 pointer-events-none">
                                                <svg class="w-4 h-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                                </svg>
                                            </div>
                                        </div>
                                        <div class="relative" v-if="!isMappingDisabled(action, mapping)">
                                            <select class="form-select" v-model="mapping.destination_attribute_id" :disabled="isMappingDisabled(action, mapping)">
                                                <option :value="null">- Select Attribute -</option>
                                                <option v-for="attribute in seebottalk_attributes" v-bind:key="attribute.id" :value="attribute.id">{{ attribute.name }}</option>
                                            </select>
                                            <div class="absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 pointer-events-none">
                                                <svg class="w-4 h-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                                </svg>
                                            </div>
                                        </div>
                                        <p class="text-xs italic text-red-500" v-if="errors && errors['params.actions.'+action_index+'.mappings.'+mapping_index+'.destination_attribute_id']">{{ errors['params.actions.'+action_index+'.mappings.'+mapping_index+'.destination_attribute_id'][0] }}</p>
                                    </div>
                                    <div class="w-full px-3 mb-6 md:w-1/5 md:mb-0" v-if="action.service.service === 'seebottext'">
                                        <label class="form-label" for="mapping_attribute">Destination Attribute</label>
                                        <div class="relative" v-if="isMappingDisabled(action, mapping)">
                                            <select class="form-select" v-model="mapping.destination_attribute_id" :disabled="isMappingDisabled(action, mapping)">
                                                <option :value="1">Phone Number</option>
                                            </select>
                                            <div class="absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 pointer-events-none">
                                                <svg class="w-4 h-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                                </svg>
                                            </div>
                                        </div>
                                        <div class="relative" v-if="!isMappingDisabled(action, mapping)">
                                            <select class="form-select" v-model="mapping.destination_attribute_id" :disabled="isMappingDisabled(action, mapping)">
                                                <option :value="null">- Select Attribute -</option>
                                                <option v-for="attribute in seebottext_attributes" v-bind:key="attribute.id" :value="attribute.id">{{ attribute.name }}</option>
                                            </select>
                                            <div class="absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 pointer-events-none">
                                                <svg class="w-4 h-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                                </svg>
                                            </div>
                                        </div>
                                        <p class="text-xs italic text-red-500" v-if="errors && errors['params.actions.'+action_index+'.mappings.'+mapping_index+'.destination_attribute_id']">{{ errors['params.actions.'+action_index+'.mappings.'+mapping_index+'.destination_attribute_id'][0] }}</p>
                                    </div>
                                    <div class="w-full px-3 mb-6 md:w-1/5 md:mb-0" v-if="action.service.service === 'seebotchat'">
                                        <label class="form-label" for="mapping_attribute">Destination Attribute</label>
                                        <div class="relative">
                                            <select class="form-select" v-model="mapping.destination_attribute_id" :disabled="isMappingDisabled(action, mapping)">
                                                <option :value="null">- Select Attribute -</option>
                                                <option v-for="attribute in seebotchat_attributes" v-bind:key="attribute.id" :value="attribute.id">{{ attribute.name }}</option>
                                            </select>
                                            <div class="absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 pointer-events-none">
                                                <svg class="w-4 h-4 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                                </svg>
                                            </div>
                                        </div>
                                        <p class="text-xs italic text-red-500" v-if="errors && errors['params.actions.'+action_index+'.mappings.'+mapping_index+'.destination_attribute_id']">{{ errors['params.actions.'+action_index+'.mappings.'+mapping_index+'.destination_attribute_id'][0] }}</p>
                                    </div>
                                    <!-- Destination Path -->
                                    <div class="w-full px-3 mb-6 md:w-1/5 md:mb-0" v-if="action.service.service === 'actionnetwork'">
                                        <label class="form-label" for="mapping_attribute">Destination Path</label>
                                        <input class="form-text" id="mapping_value" type="text" v-model="mapping.destination_path" :placeholder="getPlatformPlaceholder(action.service.service)" :disabled="isMappingDisabled(action, mapping)">
                                        <p class="text-xs italic text-red-500" v-if="errors && errors['params.actions.'+action_index+'.mappings.'+mapping_index+'.destination_path']">{{ errors['params.actions.'+action_index+'.mappings.'+mapping_index+'.destination_path'][0] }}</p>
                                    </div>
                                    <div class="w-full px-3 mb-6 md:w-1/5 md:mb-0" v-if="action.service.service === 'mailchimp'">
                                        <label class="form-label" for="mapping_attribute">Destination Path</label>
                                        <input class="form-text" id="mapping_value" type="text" v-model="mapping.destination_path" :placeholder="getPlatformPlaceholder(action.service.service)" :disabled="isMappingDisabled(action, mapping)">
                                        <p class="text-xs italic text-red-500" v-if="errors && errors['params.actions.'+action_index+'.mappings.'+mapping_index+'.destination_path']">{{ errors['params.actions.'+action_index+'.mappings.'+mapping_index+'.destination_path'][0] }}</p>
                                    </div>
                                    <div class="w-full px-3 mb-6 md:w-1/5 md:mb-0">
                                        <label class="form-label" for="mapping_value">Default Value</label>
                                        <input class="form-text" id="mapping_value" type="text" v-model="mapping.value" :disabled="isMappingDisabled(action, mapping)">
                                        <p class="text-xs italic text-red-500" v-if="errors && errors['params.actions.'+action_index+'.mappings.'+mapping_index+'.value']">{{ errors['params.actions.'+action_index+'.mappings.'+mapping_index+'.value'][0] }}</p>
                                    </div>

                                    <!-- Remove Button -->
                                    <div class="w-full px-3 pt-6 mb-6 md:w-1/5 md:mb-0">
                                        <button class="btn btn-alert" @click="removeMapping(action_index, mapping_index)" v-if="!isMappingDisabled(action, mapping)"><i class="fas fa-minus-circle"></i> Remove</button>
                                    </div>
                                </div>
                                <div class="w-full mx-3 mb-6 text-left">
                                    <button class="px-4 py-2 mr-3 font-bold text-white bg-blue-500 rounded hover:bg-blue-700" @click="addMapping(action_index)"><i class="fas fa-plus-circle"></i> Add</button>
                                </div>
                                <div class="w-full mx-3 mb-6 text-right">
                                    <button class="btn btn-alert" @click="removeAction(action_index)"><i class="fas fa-minus-circle"></i> Remove Action</button>
                                </div>
                            </div>

                            <div class="flex flex-wrap mx-3 mb-6">
                                <button class="px-4 py-2 mr-3 font-bold text-white bg-blue-500 rounded hover:bg-blue-700" @click="addAction()"><i class="fas fa-plus-circle"></i> Add Action</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="flex flex-wrap px-3 pt-3 m-5">
                    <button class="btn btn-primary" v-on:click="save">
                        Create Rule
                    </button>

                    <button class="btn btn-secondary" v-on:click="cancel">
                        Cancel
                    </button>
                </div>
            </div>

            <div class="w-64" v-if="show_help_section">
                <div class="m-5 ml-0 bg-white bg-indigo-100 rounded shadow-md" v-if="show_help_section === 'details'">
                    <h2 class="p-4">Rule Help</h2>
                    <div class="px-4 pb-4">
                        <div class="w-full">
                            <p class="pb-2 text-xs"></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
    import LayoutApp from '../../layouts/App'
    import Vue from 'vue'

    export default {
        name: 'CreateRule',
        components: {
            LayoutApp
        },
        created() {
            this.$emit('update:layout', LayoutApp);
            this.fetchServices()
            this.fetchUser()
        },
        data() {
            return {
                user: {
                    id: null,
                    permissions: {
                        chat: false,
                        talk: false,
                        text: false,
                        links: false,
                    }
                },
                rule: {
                    name: '',
                    status: 'active',
                    service: null,
                    params: {
                        criteria: [
                            {
                                types: [],
                                attribute_operator: 'AND',
                                attributes: [
                                ]
                            }
                        ],
                        actions: [

                        ]
                    }
                },
                services: [],
                selected_types: [],
                actblue_criteria_types: [
                    'donation', 'cancelation', 'refund',
                ],
                seebottext_criteria_types: [
                    'create', 'update', 'deleted',
                ],
                seebottalk_criteria_types: [
                    'create', 'update', 'deleted',
                ],
                seebottext_sequences: [

                ],
                seebottext_attributes: [

                ],
                seebottalk_sequences: [

                ],
                seebottalk_attributes: [

                ],
                seebotchat_attributes: [

                ],
                seebotchat_sequences: [

                ],
                errors: [],
                show_help_section: null,
            }
        },
        methods: {
            fetchUser() {
                axios.get(this.userUrl()).then(this.refreshUser);
            },
            userUrl() {
                return `/api/v1/me`;
            },
            refreshUser({data}) {
                this.user = data.data;

                if (this.user.permissions.talk) {
                    this.fetchTalkAttributes()
                }

                if (this.user.permissions.text) {
                    this.fetchTextAttributes()
                }

                if (this.user.permissions.chat) {
                    this.fetchChatAttributes()
                }
            },
            fetchServices() {
                axios.get(`/api/v1/services?per_page=-1`).then(this.refreshServices);
            },
            refreshServices({data}) {
                this.services = data.data;
            },
            fetchTalkAttributes() {
                axios.get(`${process.env.MIX_TALK_URL}attributes?per_page=-1`).then(this.refreshTalkAttributes);
            },
            fetchTextAttributes() {
                axios.get(`${process.env.MIX_TEXT_URL}attributes?per_page=-1`).then(this.refreshTextAttributes);
            },
            fetchChatAttributes() {
                axios.get(`${process.env.MIX_CHAT_URL}attributes?per_page=-1`).then(this.refreshChatAttributes);
            },
            refreshTalkAttributes({data}) {
                this.seebottalk_attributes = data.data;
            },
            refreshTextAttributes({data}) {
                this.seebottext_attributes = data.data;
            },
            refreshChatAttributes({data}) {
                this.seebotchat_attributes = data.data;
            },
            fetchTalkSequences(action_index, number_id) {
                axios.get(`${process.env.MIX_TALK_URL}sequences?per_page=-1&number_id=${number_id}`).then(response => this.refreshTalkSequences(response.data, action_index));
            },
            fetchTextSequences(action_index, number_id) {
                axios.get(`${process.env.MIX_TEXT_URL}sequences?per_page=-1&number_id=${number_id}`).then(response => this.refreshTextSequences(response.data, action_index));
            },
            fetchChatSequences(action_index, number_id) {
                axios.get(`${process.env.MIX_CHAT_URL}sequences?per_page=-1&number_id=${number_id}`).then(response => this.refreshChatSequences(response.data, action_index));
            },
            fetchMailchimpAudiences(action_index) {
                axios.get(`/api/v1/services/${this.rule.params.actions[action_index].service.id}/support?type=mc_audiences`).then(response => this.refreshMailchimpAudiences(response.data, action_index));
            },
            refreshTalkSequences(data, action_index) {
                Vue.set(this.rule.params.actions[action_index], 'sequences', data.data)
            },
            refreshTextSequences(data, action_index) {
                Vue.set(this.rule.params.actions[action_index], 'sequences', data.data)
            },
            refreshChatSequences(data, action_index) {
                Vue.set(this.rule.params.actions[action_index], 'sequences', data.data)
            },
            refreshMailchimpAudiences(data, action_index) {
                Vue.set(this.rule.params.actions[action_index], 'audiences', data.data)
            },
            save() {
                let item = {
                    name: this.rule.name,
                    status: this.rule.status,
                    service_id: this.rule.service.id,
                    params: {
                        criteria: this.rule.params.criteria,
                        actions: [],
                    }
                }

                this.rule.params.actions.forEach(function(action) {
                    item.params.actions.push({
                        mappings: action.mappings,
                        service_id: action.service.id,
                        trigger: action.trigger,
                    })
                })


                axios.post('/api/v1/rules', item).then(response => {
                    this.$notify({
                        group: 'alerts',
                        type: 'success',
                        title: 'The rule has been created',
                        duration: 5000,
                        speed: 1000
                    });

                    this.$router.push({name: 'list_rules'});
                }).catch(error => {
                    if (error.response.status === 422) {
                        this.errors = error.response.data.errors || {};
                    }
                });
            },
            cancel() {
                this.$router.back();
            },
            ruleUpdated() {
                if (this.rule.rule !== '' && this.rule.name === '') {
                    this.rule.name = this.rule.rule
                }
            },
            addCriteria() {
                let criteria = {
                    types: [],
                    attribute_operator: 'OR',
                    attributes: [
                    ]
                }

                this.rule.params.criteria.push(criteria)
            },
            removeCriteria(criteria_index) {
                 Vue.delete(this.rule.params.criteria, criteria_index)
            },
            addAttribute(criteria_index) {
                let attribute = {
                    path: '',
                    attribute_id: null,
                    operator: '=',
                    value: ''
                }

                this.rule.params.criteria[criteria_index].attributes.push(attribute)
            },
            removeAttribute(criteria_index, attribute_index) {
                Vue.delete(this.rule.params.criteria[criteria_index].attributes, attribute_index)
            },
            addAction() {
                let action = {
                    service: null,
                    trigger: {
                        sequence_id: null,
                        queued: false,
                        subscribers: 'all',
                        audience_id: null,
                    },
                    mappings: [
                    ],
                }

                this.rule.params.actions.push(action)
            },
            removeAction(action_index) {
                 Vue.delete(this.rule.params.actions, action_index)
            },
            addMapping(action_index) {
                let mapping = {
                    source_path: '',
                    source_attribute_id: null,
                    converter_rule: null,
                    destination_attribute_id: null,
                    destination_path: '',
                    value: ''
                }

                this.rule.params.actions[action_index].mappings.push(mapping)
            },
            removeMapping(action_index, mapping_index) {
                Vue.delete(this.rule.params.actions[action_index].mappings, mapping_index)
            },
            updateService() {
                this.rule.params = {
                    criteria: [
                        {
                            types: [],
                            attribute_operator: 'OR',
                            attributes: [
                            ]
                        }
                    ],
                    actions: [

                    ]
                }
            },
            updateActionService(action_index) {
                let mapping = null;

                if (this.rule.params.actions[action_index].service.service === 'seebottext' || this.rule.params.actions[action_index].service.service === 'seebottalk') {
                    mapping = [
                        {
                            source_path: '',
                            source_attribute_id: null,
                            converter_rule: null,
                            destination_attribute_id: 1,
                            destination_path: '',
                            value: '',
                        }
                    ]

                    if (this.rule.service.service === 'seebottext' || this.rule.service.service === 'seebottalk') {
                        mapping[0].source_attribute_id = 1
                    }
                } else if (this.rule.params.actions[action_index].service.service === 'mailchimp') {
                    mapping = [
                        {
                            source_path: '',
                            source_attribute_id: null,
                            converter_rule: null,
                            destination_attribute_id: null,
                            destination_path: '$.email',
                            value: '',
                        }
                    ]

                    if (this.rule.service.service === 'seebottext' || this.rule.service.service === 'seebottalk') {
                        mapping[0].source_attribute_id = 1
                    }
                } else {
                    mapping = [
                        {
                            source_path: '',
                            source_attribute_id: null,
                            converter_rule: null,
                            destination_attribute_id: null,
                            destination_path: '',
                            value: '',
                        }
                    ]
                }

                this.rule.params.actions[action_index].mappings = mapping

                if (this.rule.params.actions[action_index].service.service === 'seebottext') {
                    this.fetchTextSequences(action_index, this.rule.params.actions[action_index].service.params.number_id)
                } else if (this.rule.params.actions[action_index].service.service === 'seebottalk') {
                    this.fetchTalkSequences(action_index)
                } else if (this.rule.params.actions[action_index].service.service === 'mailchimp') {
                    this.fetchMailchimpAudiences(action_index)
                }
            },
            isMappingDisabled(action, mapping) {
                if ((action.service.service === 'seebottext' || action.service.service === 'seebottalk') && mapping.destination_attribute_id === 1) {
                    return true
                } else if ((action.service.service === 'mailchimp') && mapping.destination_path === '$.email') {
                    return true
                } else {
                    return false
                }
            },
            getPlatformPlaceholder(service_name) {
                if (service_name === 'actblue') {
                    return '$.donor.firstname'
                } else if (service_name === 'actionnetwork') {
                    return '$.given_name'
                } else {
                    return ''
                }
            },
            showHelp(item) {
                if (item == this.show_help_section) {
                    this.show_help_section = null
                } else {
                    this.show_help_section = item
                }
            },
        }
    }

</script>
