<template>
    <div>
        <div class="flex">
            <h1 class="flex-1 ml-5 font-bold text-lg text-header">Service Requests</h1>
        </div>
        <div class="m-5 bg-white">
            <div class="bg-white shadow-md rounded my-6 flex flex-row">
                <div class="w-1/4 flex-initial text-center px-4 py-2 m-2">
                    <multiselect v-model="search_services" :options="services" :searchable="true" :close-on-select="true" :show-labels="false" :multiple="true" track-by="id" label="name" placeholder="Filter by Service" @input="updateSearch()"></multiselect>
                </div>
                <div class="w-1/4 flex-initial text-center px-4 py-2 m-2">
                    <multiselect v-model="search_statuses" :options="statuses" :searchable="true" :close-on-select="true" :show-labels="false" :multiple="true" track-by="id" label="name" placeholder="Filter by Status" @input="updateSearch()"></multiselect>
                </div>
            </div>
        </div>
        <div class="m-5 bg-white">
            <div class="">
                <div class="bg-white shadow-md rounded my-6">
                    <table class="listing">
                        <thead>
                            <tr>
                                <th><a href="javascript:void(0)" @click="updateSort('id')">ID</a></th>
                                <th><a href="javascript:void(0)" @click="updateSort('service')">Source Service</a></th>
                                <th><a href="javascript:void(0)">Results</a></th>
                                <th><a href="javascript:void(0)" @click="updateSort('status')">Status</a></th>
                                <th><a href="javascript:void(0)" @click="updateSort('created_at')">Created</a></th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="hover:bg-grey-lighter" v-for="request in requests" v-bind:key="request.id">
                                <td class="py-4 px-6 border-b border-grey-light">{{ request.id }}</td>
                                <td class="py-4 px-6 border-b border-grey-light">{{ request.service.name }}</td>
                                <td class="py-4 px-6 border-b border-grey-light">
                                    <i :title="action.service + ' - ' + action.status" v-for="action in request.service_request_actions" v-bind:key="action.id" class="p-2 fas" v-bind:class="{'fa-check-circle': (action.status === 'complete'), 'fa-question-circle': (action.status === 'skipped'), 'fa-times-circle': (action.status === 'error'), 'fa-question-circle': (action.status === 'submitted')}" @click="triggerActionDialog(action)"></i>
                                </td>
                                <td class="py-4 px-6 border-b border-grey-light">
                                    <span v-bind:class="{'bg-green-500': (request.status === 'complete'), 'bg-red-600': (request.status === 'error'), 'bg-gray-600': (request.status === 'skipped' || request.status === 'submitted')}" class="text-white font-bold py-1 px-2 rounded-full text-sm">{{ request.status | capitalize }}</span>
                                </td>

                                <td class="py-4 px-6 border-b border-grey-light">{{ request.created_at }}</td>
                                <td class="py-4 px-6 border-b border-grey-light">
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <paginate
            :page-count="page_count"
            :click-handler="fetch"
            :prev-text="'Prev'"
            :next-text="'Next'"
            :container-class="'pagination'"
            :page-class="'pagination-page'"
            :prev-class="'pagination-prev'"
            :next-class="'pagination-next'"
            :disabled-class="'pagination-inactive'">
        </paginate>

        <modal name="action-details" class="confirm-modal" :height="720">
            <div class="modal-container">
                <div class="inline-block align-bottom bg-white text-left">
                    <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                        <div class="mt-3 mx-4" v-if="current_action">
                            <h3 class="modal-c-title">Action Details - {{ getServiceName(current_action.service) }}</h3>
                            <div class="mt-2">
                                <div v-if="current_action.service === 'seebottalk'" class="overflow: scroll">
                                    <div class="pb-3">
                                        <h3 class="strong pb-1">Trigger Details</h3>
                                        <p class="text-sm text-gray-500 pl-1 pb-2"><strong>Rule</strong><br />{{ current_action.request_data.rule_id }}</p>
                                        <p class="text-sm text-gray-500 pl-1 pb-2"><strong>Phone Number</strong><br />{{ current_action.request_data.trigger.phone_number }}</p>
                                    </div>

                                    <div class="pb-3">
                                        <h3 class="strong pb-1">Mappings</h3>
                                        <p class="text-sm text-gray-500 pl-1 pb-2" v-for="(mapping, index) in current_action.request_data.mappings" v-bind:key="index">
                                            <strong>{{ getTalkAttributeByID(mapping.attribute_id) }}</strong><br />{{ mapping.value }}
                                        </p>
                                    </div>
                                </div>

                                <div v-if="current_action.service === 'seebottext'" class="overflow: scroll">
                                    <div class="pb-3">
                                        <h3 class="strong pb-1">Trigger Details</h3>
                                        <p class="text-sm text-gray-500 pl-1 pb-2"><strong>Rule</strong><br />{{ current_action.request_data.rule_id }}</p>
                                        <p class="text-sm text-gray-500 pl-1 pb-2"><strong>Phone Number</strong><br />{{ current_action.request_data.trigger.phone_number }}</p>
                                    </div>

                                    <div class="pb-3">
                                        <h3 class="strong pb-1">Mappings</h3>
                                        <p class="text-sm text-gray-500 pl-1 pb-2" v-for="(mapping, index) in current_action.request_data.mappings" v-bind:key="index">
                                            <strong>{{ getTextAttributeByID(mapping.attribute_id) }}</strong><br />{{ mapping.value }}
                                        </p>
                                    </div>
                                </div>


                                <div v-if="current_action.service === 'mailchimp'" class="overflow: scroll">
                                    <div class="pb-3">
                                        <h3 class="strong pb-1">Trigger Details</h3>
                                        <p class="text-sm text-gray-500 pl-1 pb-2"><strong>Email Address</strong><br />{{ current_action.request_data.email_address }}</p>
                                    </div>

                                    <div class="pb-3">
                                        <h3 class="strong pb-1">Merge Fields</h3>
                                        <p class="text-sm text-gray-500 pl-1 pb-2" v-for="(value, index) in current_action.request_data.merge_fields" v-bind:key="index">
                                            <strong>{{ index }}</strong><br />{{ value }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse absolute inset-x-0 bottom-0">
                        <button type="button" class="modal-button-primary m-3" @click="$modal.hide('action-details')">Close</button>
                    </div>
                </div>
            </div>
        </modal>
    </div>
</template>

<script>
    import axios from 'axios';
    import LayoutApp from '../../layouts/App'

    export default {
        name: 'ListRequests',
        components: {
            LayoutApp
        },
        created() {
            this.$emit('update:layout', LayoutApp);
            this.fetch(1);
            this.fetchTalkAttributes()
            this.fetchTextAttributes()
        },
        data() {
            return {
                requests: [],
                page_count: 1,
                sort: 'desc',
                order: 'id',
                statuses: [
                    {id: 'submitted', name: 'Submitted'},
                    {id: 'skipped', name: 'Skipped'},
                    {id: 'complete', name: 'Complete'},
                    {id: 'error', name: 'Error'},
                ],
                services: [],
                search: this.$route.query.search || '',
                search_statuses: [],
                search_services: [],
                seebottext_attributes: [],
                seebottalk_attributes: [],
                current_action: null,
            }
        },
        methods: {
            fetch(page) {
                this.current_page = page;

                let search_statuses = this.search_statuses.map(status => status.id)
                let search_services = this.search_services.map(service => service.id)

                axios.get(`/api/v1/servicerequests?page=${this.current_page}&sort=${this.sort}&order=${this.order}&search=${this.search}&search_statuses=${search_statuses}&search_services=${search_services}`).then(this.refresh);

                this.$router.push({
                    query: {
                        page: this.current_page,
                        sort: this.sort,
                        order: this.order,
                        search: this.search,
                    }
                }).catch(err => {})
            },
            refresh({data}) {
                this.requests = data.data;
                this.page_count = data.meta.pages;
                window.scrollTo(0,0);
            },
            fetchServices() {
                axios.get(`/api/v1/services?per_page=-1`).then(this.refreshServices);
            },
            refreshServices({data}) {
                this.services = data.data;
            },
            updateSort(field) {
                if (field === this.order) {
                    this.sort = (this.sort === 'asc' ? 'desc' : 'asc');
                } else {
                    this.sort = 'asc';
                }

                this.order = field;

                this.fetch(1);
            },
            updateSearch() {
                this.fetch(1);
            },
            triggerActionDialog(action) {
                this.current_action = action
                this.$modal.show('action-details')
            },
            getServiceName(service) {
                if (service === 'seebottext') {
                    return 'SeeBotText'
                } else if (service === 'seebottalk') {
                    return 'SeeBotTalk'
                } else if (service === 'seebotchat') {
                    return 'SeeBotChat'
                } else if (service === 'mailchimp') {
                    return 'MailChimp'
                } else if (service === 'actblue') {
                    return 'ActBlue'
                }
            },
            getTalkAttributeByID(id) {
                for (let i = 0 ; i < this.seebottalk_attributes.length ; ++i) {
                    if (this.seebottalk_attributes[i].id === id) {
                        return this.seebottalk_attributes[i].name
                    }
                }
            },
            getTextAttributeByID(id) {
                for (let i = 0 ; i < this.seebottext_attributes.length ; ++i) {
                    if (this.seebottext_attributes[i].id === id) {
                        return this.seebottext_attributes[i].name
                    }
                }
            },
            fetchTalkAttributes() {
                axios.get(`${process.env.MIX_TALK_URL}attributes?per_page=-1`).then(this.refreshTalkAttributes);
            },
            fetchTextAttributes() {
                axios.get(`${process.env.MIX_TEXT_URL}attributes?per_page=-1`).then(this.refreshTextAttributes);
            },
            refreshTalkAttributes({data}) {
                this.seebottalk_attributes = data.data;
            },
            refreshTextAttributes({data}) {
                this.seebottext_attributes = data.data;
            },
        }
    }
</script>
