<template>
    <div>
        <div class="flex">
            <h1 class="flex-1 ml-5 font-bold text-lg text-header">Reports</h1>
            <router-link class="mr-5 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" to="/reports/create">
                Create New Report
            </router-link>
        </div>
        <div class="m-5 bg-white">
            <div class="bg-white shadow-md rounded my-6 flex flex-row">
                <div class="w-1/3 flex-initial text-center px-4 py-2 m-2">
                    <input class="form-text" type="text" id="search" name="search" v-model="search" placeholder="Search" @blur="updateSearch()" />
                </div>
                <div class="w-1/3 flex-initial text-center px-4 py-2 m-2">
                    <multiselect v-model="search_types" :options="types" :searchable="true" :close-on-select="true" :show-labels="false" :multiple="true" track-by="id" label="name" placeholder="Filter by Types" @input="updateSearch()"></multiselect>
                </div>
                <div class="w-1/3 flex-initial text-center px-4 py-2 m-2">
                    <multiselect v-model="search_statuses" :options="statuses" :searchable="true" :close-on-select="true" :show-labels="false" :multiple="true" track-by="id" label="name" placeholder="Filter by Status" @input="updateSearch()"></multiselect>
                </div>
            </div>
        </div>
        <div class="m-5 bg-white">
            <div class="">
                <div class="bg-white shadow-md rounded my-6">
                    <table class="listing">
                        <thead>
                            <tr>
                                <th><a href="javascript:void(0)" @click="updateSort('id')">ID</a></th>
                                <th><a href="javascript:void(0)" @click="updateSort('name')">Name</a></th>
                                <th><a href="javascript:void(0)" @click="updateSort('type')">Type</a></th>
                                <th><a href="javascript:void(0)" @click="updateSort('schedule')">Run/Scheduled At</a></th>
                                <th><a href="javascript:void(0)" @click="updateSort('status')">Status</a></th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="hover:bg-grey-lighter" v-for="report in reports" v-bind:key="report.id">
                                <td class="py-4 px-6 border-b border-grey-light">{{ report.id }}</td>
                                <td class="py-4 px-6 border-b border-grey-light">{{ report.name }}</td>
                                <td class="py-4 px-6 border-b border-grey-light">{{ report.type | capitalize }}</td>
                                <td class="py-4 px-6 border-b border-grey-light">{{ report.schedule.run_at | formatDate }}</td>
                                <td class="py-4 px-6 border-b border-grey-light">
                                    <span v-bind:class="{'bg-blue-500': (report.status === 'scheduled' || report.status === 'running'), 'bg-green-500': (report.status === 'completed'), 'bg-red-600': (report.status === 'error'), 'bg-gray-600': (report.status === 'inactive')}" class="text-white font-bold py-1 px-2 rounded-full text-sm">{{ report.status | capitalize }}</span>
                                </td>
                                <td class="py-4 px-6 border-b border-grey-light">
                                    <a :href="'/reports/'+report.id+'/download'" :download="report.name+'.csv'" class="mx-3" v-if="report.status === 'completed'"><i class="fas fa-file-download"></i></a>
                                    <!--<router-link :to="{ name: 'edit_report', params: { id: report.id }}" class="mx-3"><i class="fas fa-pencil-alt"></i></router-link>-->
                                    <a href="javascript:void(0);" v-on:click="confirmDelete(report.id)" class="mx-3"><i class="fas fa-trash-alt"></i></a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <paginate
            :page-count="page_count"
            :click-handler="fetch"
            :prev-text="'Prev'"
            :next-text="'Next'"
            :container-class="'pagination'"
            :page-class="'pagination-page'"
            :prev-class="'pagination-prev'"
            :next-class="'pagination-next'"
            :disabled-class="'pagination-inactive'">
        </paginate>

        <modal name="delete-confirm" class="confirm-modal" :height="220">
            <div class="modal-container">
                <div class="modal-content">
                    <div class="modal-c-title">Delete report</div>
                    <div class="modal-c-text">Are you sure you want to delete this report?  This action cannot be undone.</div>
                </div>
                <div class="modal-buttons">
                    <button type="button" class="modal-button" @click="$modal.hide('delete-confirm')">Cancel</button>
                    <button type="button" class="modal-button-primary" @click="deleteReport()">Delete</button>
                </div>
            </div>
        </modal>
    </div>
</template>

<script>
    import axios from 'axios';
    import LayoutApp from '../../layouts/App'

    export default {
        name: 'ListReports',
        components: {
            LayoutApp
        },
        created() {
            this.$emit('update:layout', LayoutApp);
            this.fetch(1);
        },
        data() {
            return {
                reports: [],
                delete_id: null,
                page_count: 1,
                sort: 'desc',
                order: 'id',
                statuses: [
                    {id: 'scheduled', name: 'Scheduled'},
                    {id: 'running', name: 'Running'},
                    {id: 'completed', name: 'Completed'},
                    {id: 'error', name: 'Error'},
                    {id: 'inactive', name: 'Inactive'},
                ],
                types: [
                    {id: 'call_summary', name: 'Call Summary'},
                    {id: 'rep_summary', name: 'Rep Summary'},
                ],
                search: this.$route.query.search || '',
                search_statuses: [],
                search_types: [],
            }
        },
        methods: {
            fetch(page) {
                this.current_page = page;

                let search_types = this.search_types.map(type => type.id)
                let search_statuses = this.search_statuses.map(status => status.id)

                axios.get(`/api/v1/reports?page=${this.current_page}&sort=${this.sort}&order=${this.order}&search=${this.search}&search_types=${search_types}&search_statuses=${search_statuses}`).then(this.refresh);

                this.$router.push({
                    query: {
                        page: this.current_page,
                        sort: this.sort,
                        order: this.order,
                        search: this.search,
                    }
                }).catch(err => {})
            },
            refresh({data}) {
                this.reports = data.data;
                this.page_count = data.meta.pages;
                window.scrollTo(0,0);
            },
            confirmDelete(id) {
                this.delete_id = id;

                this.$modal.show('delete-confirm');
            },
            deleteReport() {
                axios.delete(`/api/v1/reports/${this.delete_id}`).then(this.postDelete);
            },
            postDelete() {
                this.$modal.hide('delete-confirm');
                this.$notify({
                    group: 'alerts',
                    type: 'success',
                    text: 'The report has been deleted',
                    duration: 5000,
                    speed: 1000
                });

                this.fetch(1);
            },
            updateSort(field) {
                if (field === this.order) {
                    this.sort = (this.sort === 'asc' ? 'desc' : 'asc');
                } else {
                    this.sort = 'asc';
                }

                this.order = field;

                this.fetch(1);
            },
            updateSearch() {
                this.fetch(1);
            },
        }
    }
</script>
