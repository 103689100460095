import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from './components/app/Home';

import ListServices from './components/app/services/ListServices';
import CreateService from './components/app/services/CreateService';
import EditService from './components/app/services/EditService';

import ListRules from './components/app/rules/ListRules';
import CreateRule from './components/app/rules/CreateRule';
import EditRule from './components/app/rules/EditRule';

import ListRequests from './components/app/requests/ListRequests';

import ListReports from './components/app/reports/ListReports';
import CreateReport from './components/app/reports/CreateReport';
import EditReport from './components/app/reports/EditReport';

import NotFound from './components/app/NotFound';

Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    routes: [
        {
            path: '*',
            component: NotFound
        },
        {
            path: '/',
            name: 'home',
            component: Home
        },
        {
            path: '/services',
            name: 'list_services',
            component: ListServices
        },
        {
            path: '/services/create',
            name: 'create_service',
            component: CreateService
        },
        {
            path: '/services/edit/:id',
            name: 'edit_service',
            component: EditService,
            props: true
        },
        {
            path: '/rules',
            name: 'list_rules',
            component: ListRules
        },
        {
            path: '/rules/create',
            name: 'create_rule',
            component: CreateRule
        },
        {
            path: '/rules/edit/:id',
            name: 'edit_rule',
            component: EditRule,
            props: true
        },
        {
            path: '/requests',
            name: 'list_requests',
            component: ListRequests
        },
        {
            path: '/reports',
            name: 'list_reports',
            component: ListReports
        },
        {
            path: '/reports/create',
            name: 'create_report',
            component: CreateReport
        },
        {
            path: '/reports/edit/:id',
            name: 'edit_report',
            component: EditReport,
            props: true
        },
    ]
});

export default router
