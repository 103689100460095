<template>
    <div>
        <div class="flex">
            <h1 class="flex-1 ml-5 font-bold text-lg text-header">Home</h1>
        </div>
        <div class="flex">
            <div class="m-5 bg-white shadow-md rounded w-full">
                <div class="">
                    <div class="bg-white my-6">
                        <div class="px-6 py-4">
                            <div class="font-bold text-xl mb-2">Inbound/Outbound</div>
                            <line-chart :data="request_summary_data"></line-chart>
                        </div>
                    </div>
                </div>
            </div>

            <div class="m-5 bg-white shadow-md rounded w-full">
                <div class="">
                    <div class="bg-white my-6">
                        <div class="px-6 py-4">
                            <div class="font-bold text-xl mb-2">Service Requests</div>
                            <column-chart :data="service_request_summary_data"></column-chart>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import axios from 'axios';
    import moment from 'moment-timezone'
    import LayoutApp from '../layouts/App'

    export default {
        name: 'home',
        components: {
            LayoutApp
        },
        created() {
            this.$emit('update:layout', LayoutApp)
            this.fetch()
            this.fetchRequestSummary()
            this.fetchServiceSummary()
        },
        data() {
            return {
                request_summary_data: null,
                service_request_summary_data: null,
            }
        },
        methods: {
            fetch() {
                axios.get(`/api/v1/home`).then(this.refresh)
            },
            refresh({data}) {
            },
            reportUrl() {
                return `/api/v1/reports/view`
            },
            fetchRequestSummary() {
                let start = moment().subtract(30, 'days').format('YYYY-MM-DD')
                let end = moment().format('YYYY-MM-DD')
                axios.get(this.reportUrl()+`/?type=requests_summary&group_by=day&start=${start}&end=${end}`).then(this.refreshRequestSummary)
            },
            refreshRequestSummary({data}) {
                this.request_summary_data = data.data
            },
            fetchServiceSummary() {
                let start = moment().subtract(30, 'days').format('YYYY-MM-DD')
                let end = moment().add(1, 'days').format('YYYY-MM-DD')
                axios.get(this.reportUrl()+`/?type=service_summary&group_by=day&start=${start}&end=${end}`).then(this.refreshServiceSummary)
            },
            refreshServiceSummary({data}) {
                this.service_request_summary_data = data.data
            }
        }
    }
</script>
